import { posthogCaptureWithUserContext } from "./general";
import {
  getCoursePropertiesForEvent,
  getExamQuestionPropertiesForEvent,
  getGeneratedQuestionPropertiesForEvent,
  getSubskillPropertiesForEvent,
  getWorksheetExamQuestionModalFilterPropertiesForEvent,
  getWorksheetPropertiesForEvent,
  getWorksheetTemplatePropertiesForEvent,
  getWorksheetTemplateQuestionPropertiesForEvent,
} from "./properties";

/**
 * Captures the event the user initiates a blank worksheet.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogEventBlankWorksheetInitiated({ triggerId }) {
  posthogCaptureWithUserContext("blank_worksheet_initiated", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user initiates a new worksheet from a template.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The initial worksheet generated.
 * @param {Parameters<typeof getWorksheetTemplatePropertiesForEvent>[0]} properties.template The template the worksheet was generated from.
 */
export function posthogEventWorksheetGeneratedFromTemplate({
  triggerId,
  worksheet = {},
  template = {},
}) {
  posthogCaptureWithUserContext("worksheet_generated_from_template", {
    trigger_id: triggerId,
    ...getWorksheetPropertiesForEvent(worksheet),
    ...getWorksheetTemplatePropertiesForEvent(template),
  });
}

/**
 * Captures the event the user opens an existing worksheet.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The worksheet opened.
 */
export function posthogEventWorksheetOpened({ triggerId, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_opened", {
    trigger_id: triggerId,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user saves a worksheet.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {boolean} properties.isNewWorksheet Whether the worksheet is new or existing.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The worksheet saved.
 */
export function posthogEventWorksheetSaved({ triggerId, isNewWorksheet, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_saved", {
    trigger_id: triggerId,
    is_new_worksheet: isNewWorksheet,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user exits without saving a worksheet.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The worksheet not saved.
 */
export function posthogEventWorksheetExitedWithoutSaving({ triggerId, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_exited_without_saving", {
    trigger_id: triggerId,
    is_new_worksheet: !worksheet.wid,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user exports a worksheet to a word document.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The worksheet exported.
 */
export function posthogEventWorksheetDownloaded({ triggerId, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_downloaded", {
    trigger_id: triggerId,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user switches the worksheet to view mode.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The worksheet switched to view mode.
 */
export function posthogEventWorksheetSwitchedToViewMode({ triggerId, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_switched_to_view_mode", {
    trigger_id: triggerId,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user switches the worksheet to edit mode.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The worksheet switched to edit mode.
 */
export function posthogEventWorksheetSwitchedToEditMode({ triggerId, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_switched_to_edit_mode", {
    trigger_id: triggerId,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user adds a blank exam question card to a worksheet.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question added to the worksheet.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The worksheet the card was added to.
 */
export function posthogEventWorksheetBlankExamQuestionAdded({ triggerId, questionNumber, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_blank_exam_question_added", {
    trigger_id: triggerId,
    question_number: questionNumber,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user opens the worksheet exam question modal for a specific question.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the worksheet the modal is for.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The current worksheet.
 */
export function posthogEventWorksheetExamQuestionModalOpened({ triggerId, questionNumber, worksheet = {} }) {
  const worksheetQuestions = worksheet.questions || [];
  const question = Number.isInteger(questionNumber) ? worksheetQuestions[questionNumber - 1] : null;
  const questionAlreadyPopulated = question ? !!question.qid : false;
  posthogCaptureWithUserContext("worksheet_exam_question_modal_opened", {
    trigger_id: triggerId,
    question_number: questionNumber,
    question_already_populated: questionAlreadyPopulated,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user closes the worksheet exam question modal without choosing a question.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the worksheet the modal is for.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The current worksheet.
 * @param {Parameters<typeof getWorksheetExamQuestionModalFilterPropertiesForEvent} properties.filters The filters set on the modal.
 */
export function posthogEventWorksheetExamQuestionModalClosedWithoutChoosing({ triggerId, questionNumber, worksheet = {}, filters = {} }) {
  const worksheetQuestions = worksheet.questions || [];
  const question = Number.isInteger(questionNumber) ? worksheetQuestions[questionNumber - 1] : null;
  const questionAlreadyPopulated = question ? !!question.qid : false;
  posthogCaptureWithUserContext("worksheet_exam_question_modal_closed_without_choosing", {
    trigger_id: triggerId,
    question_number: questionNumber,
    question_already_populated: questionAlreadyPopulated,
    ...getWorksheetPropertiesForEvent(worksheet),
    ...getWorksheetExamQuestionModalFilterPropertiesForEvent(filters),
  });
}

/**
 * Captures the event the user chooses a question from the worksheet exam question modal.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the worksheet the modal is for.
 * @param {boolean} properties.replacingQuestion Whether the question is replacing an existing question in the worksheet.
 * @param {Parameters<typeof getExamQuestionPropertiesForEvent>[0]} properties.question The exam question chosen.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The current worksheet.
 * @param {Parameters<typeof getWorksheetExamQuestionModalFilterPropertiesForEvent} properties.filters The filters set on the modal.
 */
export function posthogEventWorksheetExamQuestionChosen({ triggerId, questionNumber, replacingQuestion, question = {}, worksheet = {}, filters = {} }) {
  posthogCaptureWithUserContext("worksheet_exam_question_chosen", {
    trigger_id: triggerId,
    question_number: questionNumber,
    question_already_populated: replacingQuestion,
    ...getExamQuestionPropertiesForEvent(question),
    ...getWorksheetPropertiesForEvent(worksheet),
    ...getWorksheetExamQuestionModalFilterPropertiesForEvent(filters),
  });
}

/**
 * Captures the event the user removes an exam question card from a worksheet.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question card removed from the worksheet.
 * @param {Parameters<typeof getExamQuestionPropertiesForEvent>[0] | undefined} properties.question The exam question removed, if any.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The worksheet the card was removed from.
 */
export function posthogEventWorksheetExamQuestionRemoved({ triggerId, questionNumber, question, worksheet = {} }) {
  const questionProperties = question ? getExamQuestionPropertiesForEvent(question) : {};
  posthogCaptureWithUserContext("worksheet_exam_question_removed", {
    trigger_id: triggerId,
    question_number: questionNumber,
    question_already_populated: !!question,
    ...questionProperties,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user adds a blank generated question card to a worksheet.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question card added to the worksheet.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The worksheet the card was added to.
 */
export function posthogEventWorksheetBlankGeneratedQuestionAdded({ triggerId, questionNumber, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_blank_generated_question_added", {
    trigger_id: triggerId,
    question_number: questionNumber,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user opens the worksheet generated question modal for a specific question.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the worksheet the modal is for.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The current worksheet.
 */
export function posthogEventWorksheetGeneratedQuestionModalOpened({ triggerId, questionNumber, worksheet = {} }) {
  const worksheetQuestions = worksheet.questions || [];
  const question = Number.isInteger(questionNumber) ? worksheetQuestions[questionNumber - 1] : null;
  const questionAlreadyPopulated = question ? !!question.ssid : false;
  posthogCaptureWithUserContext("worksheet_generated_question_modal_opened", {
    trigger_id: triggerId,
    question_number: questionNumber,
    question_already_populated: questionAlreadyPopulated,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user closes the worksheet generated question modal without choosing a question.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the worksheet the modal is for.
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0] | undefined} properties.course The course selected in the modal, if any.
 * @param {Parameters<typeof getSubskillPropertiesForEvent>[0] | undefined} properties.subskill The subskill selected in the modal, if any.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The current worksheet.
 */
export function posthogEventWorksheetGeneratedQuestionModalClosedWithoutChoosing({ triggerId, questionNumber, course, subskill, worksheet = {} }) {
  const worksheetQuestions = worksheet.questions || [];
  const question = Number.isInteger(questionNumber) ? worksheetQuestions[questionNumber - 1] : null;
  const questionAlreadyPopulated = question ? !!question.ssid : false;
  const courseProperties = course ? getCoursePropertiesForEvent(course) : {};
  const subskillProperties = subskill ? getSubskillPropertiesForEvent(subskill) : {};
  posthogCaptureWithUserContext("worksheet_generated_question_modal_closed_without_choosing", {
    trigger_id: triggerId,
    question_number: questionNumber,
    question_already_populated: questionAlreadyPopulated,
    ...courseProperties,
    ...subskillProperties,
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user chooses a question from the worksheet generated question modal.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the worksheet the modal is for.
 * @param {boolean} properties.replacingQuestion Whether the question is replacing an existing question in the worksheet.
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course The course selected in the modal.
 * @param {Parameters<typeof getGeneratedQuestionPropertiesForEvent>[0]} properties.question The generated question chosen.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The current worksheet.
 */
export function posthogEventWorksheetGeneratedQuestionChosen({ triggerId, questionNumber, replacingQuestion, course = {}, question = {}, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_generated_question_chosen", {
    trigger_id: triggerId,
    question_number: questionNumber,
    question_already_populated: replacingQuestion,
    ...getCoursePropertiesForEvent(course),
    ...getGeneratedQuestionPropertiesForEvent(question),
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user regenerates a generated question in a worksheet.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the worksheet regenerated.
 * @param {Parameters<typeof getGeneratedQuestionPropertiesForEvent>[0]} properties.question The regenerated question.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The current worksheet.
 */
export function posthogEventWorksheetGeneratedQuestionRegenerated({ triggerId, questionNumber, question = {}, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_generated_question_regenerated", {
    trigger_id: triggerId,
    question_number: questionNumber,
    ...getGeneratedQuestionPropertiesForEvent(question),
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user removes a generated question in a worksheet.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the worksheet removed.
 * @param {Parameters<typeof getGeneratedQuestionPropertiesForEvent>[0]} properties.question The generated question removed.
 * @param {Parameters<typeof getWorksheetPropertiesForEvent>[0]} properties.worksheet The current worksheet.
 */
export function posthogEventWorksheetGeneratedQuestionRemoved({ triggerId, questionNumber, question = {}, worksheet = {} }) {
  posthogCaptureWithUserContext("worksheet_generated_question_removed", {
    trigger_id: triggerId,
    question_number: questionNumber,
    ...getGeneratedQuestionPropertiesForEvent(question),
    ...getWorksheetPropertiesForEvent(worksheet),
  });
}

/**
 * Captures the event the user initiates a new worksheet template, blank or pre-populated with requested
 * subskills or as a shadow of an existing worksheet.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getWorksheetTemplatePropertiesForEvent>[0] | undefined} properties.template The template initiated, if any.
 * @param {number | undefined} properties.shadowWorksheetId The ID of the worksheet the template is a shadow of, if any.
 */
export function posthogEventWorksheetTemplateInitiated({ triggerId, template, shadowWorksheetId }) {
  const templateProperties = template ? getWorksheetTemplatePropertiesForEvent(template) : {};
  posthogCaptureWithUserContext("worksheet_template_initiated", {
    trigger_id: triggerId,
    ...templateProperties,
    shadow_worksheet_id: shadowWorksheetId,
  });
}

/**
 * Captures the event the user opens an existing worksheet template.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {Parameters<typeof getWorksheetTemplatePropertiesForEvent>[0]} properties.template The template opened.
 */
export function posthogEventWorksheetTemplateOpened({ triggerId, template = {} }) {
  posthogCaptureWithUserContext("worksheet_template_opened", {
    trigger_id: triggerId,
  ...getWorksheetTemplatePropertiesForEvent(template),
  });
}

/**
 * Captures the event the user saves a worksheet template.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {boolean} properties.isNewTemplate Whether the template is new or existing.
 * @param {Parameters<typeof getWorksheetTemplatePropertiesForEvent>[0]} properties.template The template saved.
 */
export function posthogEventWorksheetTemplateSaved({ triggerId, isNewTemplate, template = {} }) {
  posthogCaptureWithUserContext("worksheet_template_saved", {
    trigger_id: triggerId,
    is_new_template: isNewTemplate,
    ...getWorksheetTemplatePropertiesForEvent(template),
  });
}

/**
 * Captures the event the user adds a question card to a template.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question added to the template.
 * @param {Parameters<typeof getWorksheetTemplatePropertiesForEvent>[0]} properties.template The template the card was added to.
 */
export function posthogEventWorksheetTemplateQuestionAdded({ triggerId, questionNumber, template = {} }) {
  const templateQuestions = template.questions || [];
  const question = Number.isInteger(questionNumber) ? templateQuestions[questionNumber - 1] : null;
  const questionProperties = question ? getWorksheetTemplateQuestionPropertiesForEvent(question) : {};
  posthogCaptureWithUserContext("worksheet_template_question_added", {
    trigger_id: triggerId,
    question_number: questionNumber,
    ...questionProperties,
    ...getWorksheetTemplatePropertiesForEvent(template),
  });
}

/**
 * Captures the event the user opens the subskill modal for a question in a template.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the template the modal is for.
 * @param {Parameters<typeof getWorksheetTemplatePropertiesForEvent>[0]} properties.template The current template.
 */
export function posthogEventWorksheetTemplateSubskillModalOpened({ triggerId, questionNumber, template = {} }) {
  const templateQuestions = template.questions || [];
  const question = Number.isInteger(questionNumber) ? templateQuestions[questionNumber - 1] : null;
  const questionAlreadyPopulated = question ? !!question.ssid : false;
  posthogCaptureWithUserContext("worksheet_template_subskill_modal_opened", {
    trigger_id: triggerId,
    question_number: questionNumber,
    question_already_populated: questionAlreadyPopulated,
    ...getWorksheetTemplatePropertiesForEvent(template),
  });
}

/**
 * Captures the event the user closes the subskill modal without choosing a subskill for a
 * question in a template.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the template the modal is for.
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0] | undefined} properties.course The course selected in the modal, if any.
 * @param {Parameters<typeof getSubskillPropertiesForEvent>[0] | undefined} properties.subskill The subskill selected in the modal, if any.
 * @param {Parameters<typeof getWorksheetTemplatePropertiesForEvent>[0]} properties.template The current template.
 */
export function posthogEventWorksheetTemplateSubskillModalClosedWithoutChoosing({ triggerId, questionNumber, course, subskill, template = {} }) {
  const templateQuestions = template.questions || [];
  const question = Number.isInteger(questionNumber) ? templateQuestions[questionNumber - 1] : null;
  const questionAlreadyPopulated = question ? !!question.ssid : false;
  const courseProperties = course ? getCoursePropertiesForEvent(course) : {};
  const subskillProperties = subskill ? getSubskillPropertiesForEvent(subskill) : {};
  posthogCaptureWithUserContext("worksheet_template_subskill_modal_closed_without_choosing", {
    trigger_id: triggerId,
    question_number: questionNumber,
    question_already_populated: questionAlreadyPopulated,
    ...courseProperties,
    ...subskillProperties,
    ...getWorksheetTemplatePropertiesForEvent(template),
  });
}

/**
 * Captures the event the user chooses a subskill for a question in a template.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question in the template the modal is for.
 * @param {boolean} properties.replacingQuestion Whether the question is replacing an existing question in the template.
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course The course selected in the modal.
 * @param {Parameters<typeof getSubskillPropertiesForEvent>[0]} properties.subskill The subskill chosen.
 * @param {Parameters<typeof getWorksheetTemplatePropertiesForEvent>[0]} properties.template The current template.
 */
export function posthogEventWorksheetTemplateSubskillChosen({ triggerId, questionNumber, replacingQuestion, course = {}, subskill = {}, template = {} }) {
  posthogCaptureWithUserContext("worksheet_template_subskill_chosen", {
    trigger_id: triggerId,
    question_number: questionNumber,
    question_already_populated: replacingQuestion,
    ...getCoursePropertiesForEvent(course),
    ...getSubskillPropertiesForEvent(subskill),
    ...getWorksheetTemplatePropertiesForEvent(template),
  });
}

/**
 * Captures the event the user removes a question card from a template.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.questionNumber The number of the question card removed from the template.
 * @param {Parameters<typeof getWorksheetTemplateQuestionPropertiesForEvent>[0] | undefined} properties.question The question removed, if any.
 * @param {Parameters<typeof getWorksheetTemplatePropertiesForEvent>[0]} properties.template The template the card was removed from.
 */
export function posthogEventWorksheetTemplateQuestionRemoved({ triggerId, questionNumber, question, template = {} }) {
  const questionProperties = question ? getWorksheetTemplateQuestionPropertiesForEvent(question) : {};
  posthogCaptureWithUserContext("worksheet_template_question_removed", {
    trigger_id: triggerId,
    question_number: questionNumber,
    ...questionProperties,
    ...getWorksheetTemplatePropertiesForEvent(template),
  });
}

/**
 * Captures the event the user views their home worksheet directory.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {number} properties.wdid The ID of the worksheet directory.
 */
export function posthogEventWorksheetHomeDirectoryViewed({ triggerId, wdid }) {
  posthogCaptureWithUserContext("worksheet_home_directory_viewed", {
    trigger_id: triggerId,
    worksheet_directory_id: wdid,
  });
}

/**
 * Captures the event the user views the past papers worksheet
 * directory.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogEventWorksheetPastPapersDirectoryViewed({ triggerId }) {
  posthogCaptureWithUserContext("worksheets_past_papers_directory_viewed", {
    trigger_id: triggerId,
  });
}
